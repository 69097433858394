import '../../../node_modules/systemjs/dist/system.min.js';

/*
 * Support for adding a .js extension for trailing slash imports
 * Some of the npm packages we use don't import using the file extension
 */
// biome-ignore format: Rome keeps adding unnecessary ;
((global) => {
  const systemJSPrototype = global.System.constructor.prototype
  const originalResolve = systemJSPrototype.resolve

  systemJSPrototype.resolve = function (...args) {
    const result = originalResolve.apply(this, args)

    if (result.includes(".js")) {
      return result
    }

    return `${result}.js`
  }
})(typeof self !== "undefined" ? self : global)
